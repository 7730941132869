import React from "react";
import { Link } from "react-router-dom";
import "./Footertwo.css";

const FooterTwo = () => {
  return (
    <>
      <div className="centerText">
        <p>Head office</p>
        <div className="contact_office">
          306 Riverside Drive, Airds, 2560 , NSW{" "}
        </div>
        {/* 
          <div className="postal_address">
            <p>Postal Address</p>
            <p> </p>
          </div> */}

        <div className="contact_phone">
          <p>Contact Number </p>
          <p>0426 428 608 </p>
        </div>

        <span>
          Email us: &nbsp;
          <Link
            className="contact_email"
            to="mailto:dignitynchoicecare@gmail.com"
          >
            dignitynchoicecare@gmail.com
          </Link>
        </span>
      </div>
    </>
  );
};
export default FooterTwo;
