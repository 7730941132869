import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";

import Navbar from "./components/Navbar/Navbar";
import Home from "./routes/Home/Home";

import SocialNetwork from "./components/Footer/SocialNetwork/SocialNetwork";
import Services from "./routes/Services/Services";
import JoinUs from "./routes/JoinUs/JoinUs";
import About from "./routes/About/About";
import Contact from "./routes/ContactUs/ContactUs";
import Employee from "./routes/Employee/Employee";
import Apply from "./routes/Apply/Apply";
import News from "./routes/News/News";
import PageNotFound from "./routes/PageNotFound/PageNotFound";
import Motto from "./routes/Motto/Motto";

import BlogSection from "./routes/Blog/BlogSection";
import ResourcesSection from "./routes/Resources/ResourcesSection";
import FooterTwo from "./components/Footertwo/Footertwo";
import DisableRightClick from "./components/DisableRightClick";
import { useState } from "react";
import Feedbacks from "./routes/Feedbacks/Feedbacks";
// import DarkMode from "./routes/DarkMode/DarkMode";

export default function App() {
  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const today = new Date();
  //To DO:
  //Dynamic allocation of time based on location where it is navigated
  const monthName = today.toLocaleString("en-AU", { month: "long" });
  /* Added coded for display 0 for only one character for time in minutes. */
  const mins =
    today.getMinutes().toString().length < 1
      ? today.getMinutes() + 0
      : today.getMinutes();

  var dayPart = 0;
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const time1Part = today.getHours() + ":" + mins + ":" + today.getSeconds();

  const time2Part =
    today.getDate() + " " + monthName + " " + today.getFullYear();

  days.forEach((day, index) => {
    if (index === new Date().getDay()) dayPart = day;
  });

  return (
    <>
      <div className="box1">
        <DisableRightClick />
        <div className={`dark ${darkMode ? "dark-mode" : "box3"}`}>
          <div className="box4">
            <Navbar />
          </div>

          <div className="box5">
            {/* components of pages */}
            <div className="darkmodeContainer">
              <button className="btnClass" onClick={toggleDarkMode}>
                Mode
              </button>
              <div className="timeStamp">
                {time1Part + " " + dayPart + " " + time2Part}
              </div>
            </div>
            <br />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/services" element={<Services />} />
              <Route path="/resources" element={<ResourcesSection />} />
              <Route path="/join" element={<JoinUs />} />
              <Route path="/about" element={<About />} />
              <Route path="/blog" element={<BlogSection />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/employee" element={<Employee />} />
              <Route path="/apply" element={<Apply />} />
              <Route path="/feedbacks" element={<Feedbacks />} />
              {/* <Route path="/teams" element={<OurStaff />} /> */}
              <Route path="/news" element={<News />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>

          <div className="box6">
            {/* motto */}
            <Motto />
          </div>
          <div className="box7">
            <SocialNetwork />
          </div>
          <div className={`dark ${darkMode ? "dark-mode_footer" : "box8"}`}>
            {/* <Footer/> */}
            <FooterTwo />
          </div>
        </div>
        <div className={`dark ${darkMode ? "dark-mode_footer" : "box8"}`}>
          &copy;
          {/*{new Date().getFullYear()} {"DNCC"}. All Rights Reserved.*/}
          2024 {"DNCC"}. All Rights Reserved.
        </div>
      </div>
    </>
  );
}
