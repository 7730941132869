// import "./ContactForm.css";
// import { useState } from "react";

// function ContactForm() {

//   const [formState, setFormState] = useState({});

//   const changeHandler = (event) => {
//     setFormState({ ...formState, [event.target.name]: event.target.value });
//   };

//   const submit = (event) => {
//     event.preventDefault();

//     const config = {
//       SecureToken: "8590a840-d6d7-4e79-ae35-2d25080e15aa",
//       To: "dignitynchoicecare@yopmail.com",
//       From: formState.email,
//       Subject: formState.subject,
//       Body: `${formState.name} connected to you over email`,
//     };

//     if (window.Email) {
//       window.Email.send(config).then(() =>
//         alert("Your queries has been send through")
//       );
//     }
//   };
//   return (
// <div>
//       <form className="flex flex-col justify center items-center" onSubmit={submit}>
//         <input
//           type="text"
//           name="name"
//           value={formState.name || ""}
//           onChange={changeHandler}
//           placeholder="Name"
//           className="border"
//         />
//         <input
//           type="email"
//           name="email"
//           value={formState.email || ""}
//           onChange={changeHandler}
//           className="border border-blue-900"
//           placeholder="Email"
//         />
//         <input type="submit" value ="Send Message" />
//       </form>
//     </div>
//   );
// }

// export default ContactForm;
import "./ContactForm.css";
import React from "react";
import emailjs from "@emailjs/browser";
import { useState } from "react";

const ContactForm = () => {
  const serviceID = "dignitynchoicecare_";
  const templateID = "dignitynchoicecare";
  const publicKey = "I8AOuhbcRoIGNU_bE";

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  function submitForm(e) {
    e.preventDefault();

    emailjs.sendForm(serviceID, templateID, e.target, publicKey).then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
    alert("Your queries has been submitted and we will call you soon..!!");
    e.target.reset();
    /*
    TO DO: 
    1. Reset the Form after submission
    2. Phone number and email validation
    3. Email not working
  */
  }
  return (
    <div className="form-container">
      <h1> Send a message to us!</h1>
      <form onSubmit={submitForm}>
        <label>Name</label>
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <label>Phone Number</label>
        <input
          type="phone"
          name="phone"
          placeholder="Phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />
        <label>Email</label>
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <label>Message</label>
        <textarea
          name="message"
          placeholder="Message"
          rows="4"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <input type="submit" value="Send" />
      </form>
    </div>
  );
};
export default ContactForm;
